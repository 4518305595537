import React, { useEffect, useRef } from "react";
import {
  heroContainer,
  presentationContainer,
  companyLogoSloganContainer,
  slogon,
  companyResumeContainer,
  companyResume,
  logoImageWrapper,
} from "./heroSection.module.css";
import { StaticImage } from "gatsby-plugin-image";
import useOnScreen from "../../hooks/useOnScreen";

const companyResumeText =
  "Empresa joven, con personal de experiencia profesional, priorizando la mejor actitud, otorgando la mayor disponibilidad en el cuidado y mejora de la salud de las personas.";
const companySlogan = "Es bueno para la salud";
const HeroSection = () => {
  // const ref = useRef();
  // const isLogoHeroVisible = useOnScreen(ref);
  // useEffect(() => {
  //   if (isLogoHeroVisible) {
  //     document.getElementById("logoNavbar").style.visibility = "hidden";
  //   } else {
  //     document.getElementById("logoNavbar").style.visibility = "visible";
  //   }
  // }, [isLogoHeroVisible]);
  return (
    <div className={heroContainer}>
      <div className={presentationContainer}>
        <div className={companyLogoSloganContainer}>
          <div className={logoImageWrapper}>
            <StaticImage
              alt="Korall Medics logo"
              src="../../images/korall-medics-logo.svg"
              layout="constrained"
            />
          </div>
          <h2 className={slogon}>{companySlogan}</h2>
        </div>
        <div className={companyResumeContainer}>
          <p className={companyResume}>{companyResumeText}</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
