import React from "react";
import {
  aboutContainer,
  figureWrapper,
  figure2Wrapper,
  figure3Wrapper,
  infoAboutContainer,
  misionContainer,
  visionContainer,
  valuesContainer,
  valuesList,
} from "./about.module.css";
import { StaticImage } from "gatsby-plugin-image";

const Mision = () => {
  return (
    <div className={misionContainer}>
      <div className={infoAboutContainer}>
        <h2>Misión</h2>
        <p>
          Formar un equipo de excelencia profesional,con la finalidad de ofrecer
          el mejor servicio técnico y de atención oportuna, así como la mejor
          calidad en los productos, mejorando la expectativa de salud de todos
          los pacientes del personal médico al que atendemos.
        </p>
      </div>
    </div>
  );
};
const Vision = () => {
  return (
    <div className={visionContainer}>
      <div className={infoAboutContainer}>
        <h2>Visión</h2>
        <p>
          Posicionarnos como empresa líder en el mercado hospitalario y de
          dispositivos médicos de alta especialidad, trabajando siempre en la
          mejora continua, y con ello, nos permita competir en el mercado dando
          resultados que generen lealtad y confianza en todos nuestros clientes
          y proveedores.
        </p>
      </div>
    </div>
  );
};
const Values = () => {
  return (
    <div className={valuesContainer}>
      <div className={infoAboutContainer}>
        <h2>Valores</h2>
      </div>
      <ul className={valuesList}>
        <li>Compromiso</li>
        <li>Honestidad</li>
        <li>Lealtad</li>
        <li>Confianza</li>
      </ul>
    </div>
  );
};

const About = () => {
  return (
    <div className={aboutContainer}>
      <Mision />
      <Vision />
      <Values />
      <div className={figureWrapper}>
        <StaticImage
          alt="figure gray"
          src="../../images/figure1.svg"
          layout="constrained"
        />
      </div>
      <div className={figure2Wrapper}>
        <StaticImage
          alt="figure aqua"
          src="../../images/figure2.svg"
          layout="constrained"
        />
      </div>
      <div className={figure3Wrapper}>
        <StaticImage
          alt="figures koral"
          src="../../images/figure3.svg"
          layout="constrained"
        />
      </div>
    </div>
  );
};

export default About;
