import React, { useRef, useEffect } from "react";

//Components
import Layout from "../components/Layout";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";

const About = () => {
  return (
    <Layout>
      <section className="hero">
        <HeroSection />
      </section>
      <section className="about">
        <AboutSection />
      </section>
    </Layout>
  );
};

export default About;
